export default function isferie(yr, month, day) {
  let mon = month + 1;
  const feries = {
    2022: {
      9: {
        5: true
      },
      10: {
        10: true
      },
      11: {
        11: true
      },
      12: {
        25: true,
        26: true
      }
    }
  };
  let fer = false;
  if (feries[yr] !== undefined) {
    if (feries[yr][mon] !== undefined) {
      if (feries[yr][mon][day] !== undefined) {
        if (feries[yr][mon][day] === true) {
          fer = true;
        }
      }
    }
  }
  return fer;
}
