const firebaseConfig = {
  apiKey: "AIzaSyAGl1zuP5ctYKs4K7nnGWXI8SzleDyIpTo",
  authDomain: "charting-d11c7.firebaseapp.com",
  projectId: "charting-d11c7",
  storageBucket: "charting-d11c7.appspot.com",
  messagingSenderId: "778093983570",
  appId: "1:778093983570:web:4c120a94e925c01adcec8b",
  measurementId: "G-GYBFKH7NYW"
};

export default firebaseConfig;
