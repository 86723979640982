import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword
} from "firebase/auth";
import { getDatabase, set, ref } from "firebase/database";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { useState } from "react";

export default function Signin({ setusr }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [error, setError] = useState("");
    const [loginn, setLoginn] = useState(true);
    const auth = getAuth();
    const signcreate = () => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                setusr(user);
                const dba = getDatabase();
                set(ref(dba, "users/" + user.uid), {
                    username: name
                });
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setError(errorMessage);
                // ..
            });
    };
    const login = () => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                setusr(user);
                setError("Utilisateur crée");
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setError(errorMessage);
            });
    };
    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h2" gutterBottom>
                        Liste de garde
                    </Typography>

                    {!loginn && <TextField placeholder="Nom" onChange={(e) => setName(e.target.value)}/>}
                    <br /><br />
                    <TextField placeholder="Courriel" type="email" onChange={(e) => setEmail(e.target.value)} />
                    <br /><br />
                    <TextField
                        placeholder="Mot de passe"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <br /><br />
                </CardContent>
                <CardActions>
                        <Button
                            onClick={() => {
                                setLoginn(!loginn);
                            }}
                        >
                            {loginn ? "Créez votre compte" : "Retour vers login"}
                        </Button>
                    {!loginn &&
                        <Button
                            onClick={() => {
                                signcreate();
                            }}
                        >
                            Créez votre compte
                        </Button>}
                    {loginn && <Button onClick={login}>Login</Button>}
                </CardActions>
                <p>{error != "" && error}</p>
            </Card>
        </>
    );
}
