import Signin from "./Signin";
import Create from "./Create";
import App from "./App";
import Ask from "./Ask";

import { useState, useEffect } from "react";

import { getDatabase, ref, onValue } from "firebase/database";

import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';

export default function Router() {
  const [user, setUser] = useState("");
  const [users, setUsers] = useState("");
  const [appActive, setappActive] = useState(false);
  const [list, setList] = useState("");
  const [ask, setAsk] = useState(false);

  const db = getDatabase();
  useEffect(() => {
    const userlist = ref(db, `users`);
    onValue(userlist, (snapshot) => {
      const data = snapshot.val();
      setUsers(data);
    });
  }, []);

  return (
    <><Container maxWidth="md">
      {user === "" && <Signin setusr={setUser} />}
      {user !== "" && !appActive && (
        <Create
          user={user}
          users={users}
          app={setappActive}
          setList={setList}
          setAsk={setAsk}
        />
      )}
      {user !== "" && appActive && (
        <App user={user} app={setappActive} list={list} />
      )}
      {user !== "" && ask && (
        <Modal open={ask} onClose={()=>setAsk(false)}>
          <Container style={{display: "flex", height: "90vh"}}>
          <Ask
          list={list}
          uid={user.uid}
          setAppActive={setappActive}
          setAsk={setAsk}
          setList={setList}
          />

          </Container>
        </Modal>

      )}
    </Container></>
  );
}
