import { createRoot } from "react-dom/client";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

import firebaseConfig from "./firebaseConfig";

import Router from "./Router";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

root.render(<Router />);
