import "./styles.css";

import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from '@mui/material/Stack';

import React, { useState, useEffect } from "react";
import { getDatabase, set, ref, onValue } from "firebase/database";

import isferie from "./isferie";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
}

export default function App({ user, app, list }) {
  const forceUpdate = useForceUpdate();
  const [rando, setRando] = useState(1);
  const today = new Date();
  const [mon, setMon] = useState(today.getMonth());
  const [yr, setYr] = useState(today.getFullYear());

  const gmf = list;
  let myname = "";
  const [allusers, setAllusers] = useState({});
  const [calls, setCalls] = useState({});
  const [thislistdata, setThislistdata] = useState({});
  const dba = getDatabase();

  const starCountRef = ref(dba, "users/" + user.uid);
  onValue(starCountRef, (snapshot) => {
    const data = snapshot.val();
    myname = data.username;
  });

  const callist = ref(dba, `lists/${gmf}/${yr}/${mon}`);
  const getCallList = () => {
    onValue(callist, (snapshot) => {
      const data = snapshot.val();
      setCalls(data);
    });
  };

  useEffect(() => {
    getCallList();
    const userlist = ref(dba, `users`);
    onValue(userlist, (snapshot) => {
      const data = snapshot.val();
      setAllusers(data);
    });
  }, [mon, rando]);

  useEffect(() => {
    const listdata = ref(dba, `lists/${list}/admins`);
    onValue(listdata, (snapshot) => {
      const data = snapshot.val();
      setThislistdata(data);
    });
  }, []);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Satuday"
  ];
  // Sept 1  = Thursday

  let calendar = [
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0]
  ];

  const shiftnum = { wd: ["AM", "PM", "SOIR"], we: ["AM"], fer: ["AM", "PM"] };
  const first = new Date(yr, mon, 1);
  // const dd = today.toLocaleString("en-US", {
  //   timeZone: "America/New_York"
  // });
  let currentDate = 1
  let day = first.toDateString().substring(0, 3);
  if (day == "Sun") {
    currentDate = 1;
  } else if (day == "Mon") {
    currentDate = 0;
  } else if (day == "Tue") {
    currentDate = -1;
  } else if (day == "Wed") {
    currentDate = -2;
  } else if (day == "Thu") {
    currentDate = -3;
  } else if (day == "Fri") {
    currentDate = -4;
  } else if (day == "Sat") {
    currentDate = -5;
  } else{
    console.log("else")
  }

  const months = [
    "Janvier",
    "Fevrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre"
  ];

  let row = -1;
  let totalCount = 0;
  while (first.getMonth() == mon) {
    if (totalCount % 7 == 0) {
      row = row + 1;
    }
    let modulo = totalCount % 7;
    calendar[row][modulo] = currentDate;
    if (currentDate > 0) {
      first.setDate(first.getDate() + 1);
    }
    currentDate = currentDate + 1;
    totalCount = totalCount + 1;
  }

  const addMe = (yr, mon, da, n) => {
    let id = user.uid;
    let isoncall = false;
    if (calls !== null) {
      if (calls[da] !== undefined) {
        if (calls[da][n] !== undefined) {
          if (calls[da][n][id] !== undefined) {
            if (calls[da][n][id]["call"]) {
              isoncall = true;
            } else {
              isoncall = false;
            }
          }
        }
      }
    }

    if (isoncall) {
      set(ref(dba, `lists/${gmf}/${yr}/${mon}/${da}/${n}/${id}`), {
        call: false
      });
      getCallList();
    } else {
      set(ref(dba, `lists/${gmf}/${yr}/${mon}/${da}/${n}/${id}`), {
        call: true
      });
      getCallList();
    }
    forceUpdate();
  };

  return (
      <div className="App">
        <Button
            onClick={() => {
              if (mon == 0) {
                setMon(11);
                setYr(yr - 1);
              } else {
                setMon(mon - 1);
              }
            }}
        >
          Mois Précédent
        </Button>
        <Button
            onClick={() => {
              if (mon == 11) {
                setMon(0);
                setYr(yr + 1);
              } else {
                setMon(mon + 1);
              }
            }}
        >
          Mois Suivant
        </Button>
        <Button onClick={() => app(false)}>Retour</Button>
        <DialogTitle>
          {months[mon]} {yr}
        </DialogTitle>
        <DialogTitle>
          {thislistdata["name"] !== undefined && thislistdata["name"]}
        </DialogTitle>
        <Table>
          <tbody>
          <TableRow>
            {days.map((d) => {
              return <TableCell>{d}</TableCell>;
            })}
          </TableRow>
          {calendar.map((row, key) => {
            return (
                <TableRow>
                  {row.map((da, k) => {
                    return (
                        <TableCell>
                          <>
                            {da == today.getDate() && mon == today.getMonth()
                                ? mon == today.getMonth() && (
                                <Chip label={da > 0 && da} />
                            )
                                : da > 0 && da}
                          </>
                          <Stack>
                            {(row[0] == da || row[6] == da) &&
                                da > 0 &&
                                shiftnum.we.map((n, k) => {
                                  return (
                                      <Button onClick={() => addMe(yr, mon, da, n)}>
                                        <Stack>
                                          {n}:
                                          {calls !== null &&
                                              calls[da] !== undefined &&
                                              calls[da][n] !== undefined &&
                                              Object.keys(calls[da][n]).map((u) => {
                                                return (
                                                    <>
                                                      {allusers[u] !== undefined &&
                                                          calls[da][n][u]["call"] === true &&
                                                          <Chip label={allusers[u]["username"]} color="primary" variant="outlined"/>}
                                                    </>
                                                );
                                              })}
                                        </Stack>
                                      </Button>
                                  );
                                })}
                            {row[0] != da &&
                                row[6] != da &&
                                !isferie(yr, mon, da) &&
                                da > 0 &&
                                shiftnum.wd.map((n, k) => {
                                  return (
                                      <Button onClick={() => addMe(yr, mon, da, n)}>
                                        <Stack>
                                          {n}:{" "}
                                          {calls !== null &&
                                              calls[da] !== undefined &&
                                              calls[da][n] !== undefined &&
                                              Object.keys(calls[da][n]).map((u) => {
                                                return (
                                                    <>
                                                      {allusers[u] !== undefined &&
                                                          calls[da][n][u]["call"] === true &&
                                                          <Chip label={allusers[u]["username"]} color="primary" variant="outlined"/>}
                                                    </>
                                                );
                                              })}
                                        </Stack>
                                      </Button>
                                  );
                                })}
                            {row[0] != da &&
                                row[6] != da &&
                                isferie(yr, mon, da) &&
                                da > 0 &&
                                shiftnum.fer.map((n, k) => {
                                  return (
                                      <Button onClick={() => addMe(yr, mon, da, n)}>
                                        <Stack>
                                          {n}:{" "}
                                          {calls !== null &&
                                              calls[da] !== undefined &&
                                              calls[da][n] !== undefined &&
                                              Object.keys(calls[da][n]).map((u) => {
                                                return (
                                                    <>
                                                      {allusers[u] !== undefined &&
                                                          calls[da][n][u]["call"] === true &&
                                                          <Chip label={allusers[u]["username"]} color="primary" variant="outlined"/>}
                                                    </>
                                                );
                                              })}
                                        </Stack>
                                      </Button>
                                  );
                                })}
                          </Stack>
                          {calls?.da && calls?.da.username}
                        </TableCell>
                    );
                  })}
                </TableRow>
            );
          })}
          <TableRow></TableRow>
          </tbody>
        </Table>
      </div>
  );
}
