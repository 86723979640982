import { TextField } from "@mui/material";
import Button from "@mui/material/Button";

import { getDatabase, set, ref, onValue } from "firebase/database";

import { useEffect, useState } from "react";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function Create({ user, users, app, setList, setAsk }) {
    const dba = getDatabase();
    const [lists, setLists] = useState({});
    const [txtname, setTxtname] = useState("");
    const [permission, setPermission] = useState(false);
    const createlist = () => {
        const newlist = Date.now();
        const adminid = user.uid;
        if (txtname !== "") {
            set(ref(dba, `lists/${newlist}/admins`), {
                [adminid]: true,
                secretcode: newlist,
                name: txtname,
                users: {
                    [adminid]: {
                        user: true
                    }
                }
            });
            set(ref(dba, `users/${adminid}/adminfor/${newlist}`), {
                admin: true
            });
            set(ref(dba, `users/${adminid}/userfor/${newlist}`), {
                user: true
            });
            app(true);
            setList(newlist);
        } else {
            alert("Vous devez entrer un nom!");
        }
    };

    useEffect(() => {
        const listdata = ref(dba, `lists`);
        onValue(listdata, (snapshot) => {
            const data = snapshot.val();
            setLists(data);
        });
    }, []);
    return (
        <><Card>
            <CardContent>
                <Typography variant="h2" gutterBottom>Bienvenu {users[user.uid]["username"]}</Typography>
            </CardContent>
        </Card><br />
            <Card>
                <CardContent>
                    <Typography>Vous êtes admin pour les listes suivantes: </Typography>

                    {users[user.uid]["adminfor"] !== undefined &&
                        lists["1664906360559"] !== undefined &&
                        Object.keys(users[user.uid]["adminfor"]).map((m, k) => {
                            return (
                                <>
                                    {users[user.uid]["adminfor"][m]["admin"] === true && (
                                        <CardActions>
                                            <Button
                                                key={k}
                                                onClick={() => {
                                                    app(true);
                                                    setList(m);
                                                }}
                                            >
                                                {lists[m]["admins"]["name"]}
                                            </Button>
                                        </CardActions>
                                    )}
                                </>
                            );
                        })}
                </CardContent>
            </Card><br />
            <Card>
                <CardContent>
                    <Typography>Vous participez aux listes suivantes</Typography>

                    {users[user.uid]["userfor"] !== undefined &&
                        lists["1664906360559"] !== undefined &&
                        Object.keys(users[user.uid]["userfor"]).map((m, k) => {
                            return (
                                <>
                                    {users[user.uid]["userfor"][m]["user"] === true && (
                                        <>
                                            <CardActions>
                                                <Button
                                                    key={k}
                                                    onClick={() => {
                                                        app(true);
                                                        setList(m);
                                                    }}
                                                >
                                                    {lists[m]["admins"]["name"]}
                                                </Button>
                                            </CardActions>
                                        </>
                                    )}
                                </>
                            );
                        })}
                </CardContent>
            </Card><br />
            <Card>
                <CardContent>
                    <Typography>Les autres listes de garde : </Typography>

                    {lists !== null &&
                        Object.keys(lists).map((m, k) => {
                            let access = false;
                            if (true) {
                                if (lists[m] !== undefined) {
                                    if (lists[m]["admins"] !== undefined) {
                                        if (lists[m]["admins"]["users"] !== undefined) {
                                            if (lists[m]["admins"]["users"][user.uid] !== undefined) {
                                                if (
                                                    lists[m]["admins"]["users"][user.uid]["user"] === true
                                                ) {
                                                    access = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if (!access) {
                            }
                            return (
                                <>
                                    {!access && (
                                        <Button
                                            key={k}
                                            onClick={() => {
                                                setAsk(true);
                                                setList(m);
                                            }}
                                        >
                                            {lists[m]["admins"]["name"]}
                                        </Button>
                                    )}
                                </>
                            );
                        })}
                </CardContent>
            </Card><br />
            {/*<Card>*/}
            {/*    <CardContent>*/}
            {/*        <Typography>Creer une nouvelle liste de garde : </Typography>*/}
            {/*        <Typography>Veuillez entrer un nom descriptif avec le nom du milieu : </Typography>*/}
            {/*        <TextField*/}
            {/*            onChange={(e) => {*/}
            {/*                setTxtname(e.target.value);*/}
            {/*            }}*/}
            {/*        />*/}
            {/*        <CardActions>*/}
            {/*            <Button onClick={createlist}>Créez cette nouvelle liste</Button>*/}
            {/*        </CardActions>*/}
            {/*    </CardContent>*/}
            {/*</Card>*/}
        </>
    );
}
