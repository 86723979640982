import { TextField } from "@mui/material";

import { getDatabase, set, ref } from "firebase/database";

import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";

export default function Ask({ list, uid, setAppActive, setAsk, setList }) {
  const dba = getDatabase();

  return (
    <div style={{backgroundColor: "white", margin: "auto", padding: "3em", borderRadius: "1em"}}>
      <Typography>Please enter secret code</Typography>
      <div>
        <TextField
          onChange={(e) => {
            if (list.slice(-6) === e.target.value) {
              set(ref(dba, `users/${uid}/userfor/${list}`), {
                user: true
              });
              set(ref(dba, `lists/${list}/admins/users/${uid}`), {
                user: true
              });
              setAppActive(true);
              setAsk(false);
              setList(list);
            }
          }}
        />
      </div>
        <Button onClick={()=>setAsk(false)}>Retour</Button>
    </div>
  );
}
